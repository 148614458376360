export enum ChubbsPlaceholderType {
  Typing = 'Typing',
  Undefined = 'Undefined',
  Phone = 'Phone',
  Send = 'Send',
  Fine = 'Fine',
  Reading = 'Reading',
}

export enum ChubbsPlaceholderBackground {
  BrandSecondary = 'brandSecondary',
  PurpleSecondary = 'accentPurpleSecondary',
  WarningSecondary = 'warningSecondary',
  Neutral = 'neutral',
  Info = 'info',
}
