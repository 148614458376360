import React, { FC } from 'react';
import { ChubbsProps } from '@/components/ui/Chubbs/chubbs.typedefs';
import {
  ChubbsWrapper,
} from '@/components/ui/Chubbs/ChubbsWrapper';
import { colorTokens } from '@/components/ui/colorTokens';

export const ChubbsPhoneLight: FC<ChubbsProps> = React.memo((props) => (
  <ChubbsWrapper initialHeight={193} initialWidth={94} {...props}>
    <g clipPath="url(#clip0_19425_3735)">
      <path
        d="M15.07 27.6799H7.26997C5.86164 27.6799 4.71997 28.8216 4.71997 30.2299V47.3099C4.71997 48.7183 5.86164 49.8599 7.26997 49.8599H15.07C16.4783 49.8599 17.62 48.7183 17.62 47.3099V30.2299C17.62 28.8216 16.4783 27.6799 15.07 27.6799Z"
        fill="#22262F"
      />
      <path
        d="M15.07 27.6799H7.26997C5.86164 27.6799 4.71997 28.8216 4.71997 30.2299V47.3099C4.71997 48.7183 5.86164 49.8599 7.26997 49.8599H15.07C16.4783 49.8599 17.62 48.7183 17.62 47.3099V30.2299C17.62 28.8216 16.4783 27.6799 15.07 27.6799Z"
        stroke="#22262F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.68 44.2699C36.24 49.7999 32.25 56.1599 22.68 62.7899C19.6 64.9199 17.6 66.5799 16.96 66.8699C16.96 66.8699 17.05 53.5199 17.05 53.0899C17 51.9999 20.42 50.7999 21.69 45.9999C22.0045 44.9448 21.9902 43.8188 21.649 42.772C21.3078 41.7252 20.6559 40.8071 19.78 40.1399C17.12 37.7999 14.53 38.9399 15.78 42.0799C16.271 42.9937 16.8907 43.8322 17.62 44.5699V47.3499C17.62 47.3499 10.62 46.4099 7.16999 46.4199C5.83999 46.4199 4.79999 46.7599 5.41999 46.4999C8.17999 45.3299 9.15999 41.3599 6.34999 41.4399C7.07571 41.3075 7.72721 40.9123 8.17999 40.3299C8.71999 39.3299 8.81999 37.5899 6.29999 37.3299C6.17999 37.3299 8.03999 37.4899 8.73999 36.8199C9.43999 36.1499 9.99999 33.4799 5.60999 32.9999C3.43999 32.7499 2.26999 34.4799 3.07999 37.5599C3.19999 37.9899 -0.350013 37.7499 1.45999 42.4099C-2.06001 47.8299 5.95999 51.6199 6.05999 53.5899C6.52999 62.5899 5.05999 72.9299 6.57999 76.8299C10.11 85.9199 20.58 80.3099 35.24 69.1799C40.36 65.3099 43.24 53.6599 41.77 47.4299C41.72 47.1599 41.68 44.2699 41.68 44.2699Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M41.68 44.2699C36.24 49.7999 32.25 56.1599 22.68 62.7899C19.6 64.9199 17.6 66.5799 16.96 66.8699C16.96 66.8699 17.05 53.5199 17.05 53.0899C17 51.9999 20.42 50.7999 21.69 45.9999C22.0045 44.9448 21.9902 43.8188 21.649 42.772C21.3078 41.7252 20.6559 40.8071 19.78 40.1399C17.12 37.7999 14.53 38.9399 15.78 42.0799C16.271 42.9937 16.8907 43.8322 17.62 44.5699V47.3499C17.62 47.3499 10.62 46.4099 7.16999 46.4199C5.83999 46.4199 4.79999 46.7599 5.41999 46.4999C8.17999 45.3299 9.15999 41.3599 6.34999 41.4399C7.07571 41.3075 7.72721 40.9123 8.17999 40.3299C8.71999 39.3299 8.81999 37.5899 6.29999 37.3299C6.17999 37.3299 8.03999 37.4899 8.73999 36.8199C9.43999 36.1499 9.99999 33.4799 5.60999 32.9999C3.43999 32.7499 2.26999 34.4799 3.07999 37.5599C3.19999 37.9899 -0.350013 37.7499 1.45999 42.4099C-2.06001 47.8299 5.95999 51.6199 6.05999 53.5899C6.52999 62.5899 5.05999 72.9299 6.57999 76.8299C10.11 85.9199 20.58 80.3099 35.24 69.1799C40.36 65.3099 43.24 53.6599 41.77 47.4299C41.72 47.1599 41.68 44.2699 41.68 44.2699Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99998 45L15.08 44.72C15.7563 44.72 16.4049 44.9886 16.8831 45.4669C17.3613 45.9451 17.63 46.5937 17.63 47.27C17.63 47.9463 17.3613 48.5949 16.8831 49.0731C16.4049 49.5513 15.7563 49.82 15.08 49.82H7.27998C6.60367 49.82 5.95507 49.5513 5.47685 49.0731C4.99863 48.5949 4.72998 47.9463 4.72998 47.27C4.72998 47.27 4.90998 46.48 5.42998 46.46C7.16998 46.42 7.99998 45.09 8.99998 45Z"
        fill="#22262F"
      />
      <path
        d="M17.63 47.27C17.63 47.9463 17.3613 48.5949 16.8831 49.0731C16.4049 49.5513 15.7563 49.82 15.08 49.82H7.27998C6.60367 49.82 5.95507 49.5513 5.47685 49.0731C4.99863 48.5949 4.72998 47.9463 4.72998 47.27C4.72998 47.27 4.90998 46.48 5.42998 46.46"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9299 66.16C16.9299 66.61 16.9299 70.34 16.9299 71.45C16.9918 69.8234 17.3974 68.2285 18.1199 66.77C18.1199 66.77 18.8199 65.53 18.6499 65.67C18.1611 66.0013 17.6421 66.2859 17.0999 66.52C17.0699 66.46 16.9999 66.17 16.9299 66.16Z"
        fill="#2A2F3C"
      />
      <path
        d="M16.9299 66.16C16.9299 66.61 16.9299 70.34 16.9299 71.45C16.9918 69.8234 17.3974 68.2285 18.1199 66.77C18.1199 66.77 18.8199 65.53 18.6499 65.67C18.1611 66.0013 17.6421 66.2859 17.0999 66.52C17.0699 66.46 16.9999 66.17 16.9299 66.16Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.69 44.8899L38.32 48.0499C36.32 57.3299 33.32 62.7699 31.76 71.8599C33.76 70.1999 35.2 68.8599 37.21 67.1599L41.69 44.8899Z"
        fill="#2A2F3C"
      />
      <path
        d="M41.69 44.8899L38.32 48.0499C36.32 57.3299 33.32 62.7699 31.76 71.8599C33.76 70.1999 35.2 68.8599 37.21 67.1599L41.69 44.8899Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.12 175.37H57.12C57.2881 175.375 57.448 175.444 57.567 175.563C57.6859 175.682 57.755 175.842 57.76 176.01L58.76 190.17H25.13C24.5 184.24 28.85 182.44 33.97 179.9C36 178.43 36.12 175.37 36.12 175.37Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M36.12 175.37H57.12C57.2881 175.375 57.448 175.444 57.567 175.563C57.6859 175.682 57.755 175.842 57.76 176.01L58.76 190.17H25.13C24.5 184.24 28.85 182.44 33.97 179.9C36 178.43 36.12 175.37 36.12 175.37Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.12 187.9H25.14C24.7865 187.9 24.5 188.186 24.5 188.54V191.57C24.5 191.923 24.7865 192.21 25.14 192.21H59.12C59.4735 192.21 59.76 191.923 59.76 191.57V188.54C59.76 188.186 59.4735 187.9 59.12 187.9Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M59.12 187.9H25.14C24.7865 187.9 24.5 188.186 24.5 188.54V191.57C24.5 191.923 24.7865 192.21 25.14 192.21H59.12C59.4735 192.21 59.76 191.923 59.76 191.57V188.54C59.76 188.186 59.4735 187.9 59.12 187.9Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.7999 175.37H77.7999C77.9673 175.377 78.1258 175.447 78.2443 175.566C78.3628 175.684 78.4326 175.843 78.4399 176.01L79.4399 190.17H45.8099C45.1799 184.24 49.5299 182.44 54.6499 179.9C56.6399 178.43 56.7999 175.37 56.7999 175.37Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M56.7999 175.37H77.7999C77.9673 175.377 78.1258 175.447 78.2443 175.566C78.3628 175.684 78.4326 175.843 78.4399 176.01L79.4399 190.17H45.8099C45.1799 184.24 49.5299 182.44 54.6499 179.9C56.6399 178.43 56.7999 175.37 56.7999 175.37Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.7999 187.9H45.8199C45.4665 187.9 45.1799 188.186 45.1799 188.54V191.57C45.1799 191.923 45.4665 192.21 45.8199 192.21H79.7999C80.1534 192.21 80.4399 191.923 80.4399 191.57V188.54C80.4399 188.186 80.1534 187.9 79.7999 187.9Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M79.7999 187.9H45.8199C45.4665 187.9 45.1799 188.186 45.1799 188.54V191.57C45.1799 191.923 45.4665 192.21 45.8199 192.21H79.7999C80.1534 192.21 80.4399 191.923 80.4399 191.57V188.54C80.4399 188.186 80.1534 187.9 79.7999 187.9Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.0002 14.7501C42.0002 23.3001 42.0002 23.1601 42.0002 31.7501C42.0002 54.3501 31.7202 59.1201 31.0702 82.3801C30.9502 86.6301 34.7602 86.1001 35.4002 91.8101C40.2102 92.9801 53.0502 88.2801 57.8502 89.4501C62.6402 88.2801 75.2802 97.7801 80.0702 96.6101C88.4802 59.6801 70.7302 52.4901 70.7602 33.2501V14.7901C70.8202 -4.19991 42.0602 -3.54991 42.0002 14.7501Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M42.0002 14.7501C42.0002 23.3001 42.0002 23.1601 42.0002 31.7501C42.0002 54.3501 31.7202 59.1201 31.0702 82.3801C30.9502 86.6301 34.7602 86.1001 35.4002 91.8101C40.2102 92.9801 53.0502 88.2801 57.8502 89.4501C62.6402 88.2801 75.2802 97.7801 80.0702 96.6101C88.4802 59.6801 70.7302 52.4901 70.7602 33.2501V14.7901C70.8202 -4.19991 42.0602 -3.54991 42.0002 14.7501Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.0001 14.75C42.0001 23.3 42.0001 23.16 42.0001 31.75C42.0082 34.2627 41.8679 36.7737 41.5801 39.27H71.3501C70.9499 37.2952 70.7522 35.2848 70.7601 33.27V14.81C70.8201 -4.20003 42.0601 -3.55003 42.0001 14.75Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M42.0001 14.75C42.0001 23.3 42.0001 23.16 42.0001 31.75C42.0082 34.2627 41.8679 36.7737 41.5801 39.27H71.3501C70.9499 37.2952 70.7522 35.2848 70.7601 33.27V14.81C70.8201 -4.20003 42.0601 -3.55003 42.0001 14.75Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.7299 13.2301C69.2999 -3.63994 43.6299 -3.08994 42.1099 13.2301H70.7299Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M70.7299 13.2301C69.2999 -3.63994 43.6299 -3.08994 42.1099 13.2301H70.7299Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.0501 81.72C82.0501 81.93 82.0501 82.13 82.0501 82.34C82.2301 92.01 79.1101 97.34 79.14 106.17C79.14 125.32 79.7201 174.55 79.6601 175.55H35.0001C35.0001 175.55 34.6 125.44 34.88 106.66C35.02 96.66 32.1201 93.8 31.0401 82.45C31.0251 82.2069 31.0251 81.9631 31.0401 81.72H82.0501Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M82.0501 81.72C82.0501 81.93 82.0501 82.13 82.0501 82.34C82.2301 92.01 79.11 97.34 79.14 106.17C79.14 125.32 79.7201 174.55 79.6601 175.55H35.0001C35.0001 175.55 34.6 125.44 34.88 106.66C35.02 96.66 32.1201 93.8 31.0401 82.45C31.0251 82.2069 31.0251 81.9631 31.0401 81.72H82.0501Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.3101 24.7101C43.6387 24.71 43.9599 24.613 44.2336 24.4312C44.5073 24.2494 44.7213 23.9909 44.8488 23.6881C44.9763 23.3853 45.0116 23.0516 44.9504 22.7287C44.8891 22.4059 44.734 22.1084 44.5044 21.8733C44.2748 21.6382 43.981 21.4761 43.6598 21.4072C43.3385 21.3383 43.004 21.3658 42.6983 21.486C42.3925 21.6063 42.129 21.8142 41.9408 22.0835C41.7526 22.3528 41.648 22.6716 41.6401 23.0001C41.6348 23.2228 41.674 23.4442 41.7556 23.6515C41.8371 23.8587 41.9593 24.0476 42.1149 24.2069C42.2705 24.3663 42.4564 24.4929 42.6617 24.5793C42.8669 24.6657 43.0874 24.7102 43.3101 24.7101Z"
        fill="white"
      />
      <path
        d="M43.3101 24.7101C43.6387 24.71 43.9599 24.613 44.2336 24.4312C44.5073 24.2494 44.7213 23.9909 44.8488 23.6881C44.9763 23.3853 45.0116 23.0516 44.9504 22.7287C44.8891 22.4059 44.734 22.1084 44.5044 21.8733C44.2748 21.6382 43.981 21.4761 43.6598 21.4072C43.3385 21.3383 43.004 21.3658 42.6983 21.486C42.3925 21.6063 42.129 21.8142 41.9408 22.0835C41.7526 22.3528 41.648 22.6716 41.6401 23.0001C41.6348 23.2228 41.674 23.4442 41.7556 23.6515C41.8371 23.8587 41.9593 24.0476 42.1149 24.2069C42.2705 24.3663 42.4564 24.4929 42.6617 24.5793C42.8669 24.6657 43.0874 24.7102 43.3101 24.7101V24.7101Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.3299 21.3701C56.9979 21.3702 56.6735 21.4692 56.3981 21.6546C56.1227 21.8399 55.9087 22.1031 55.7836 22.4106C55.6584 22.7181 55.6278 23.0559 55.6955 23.3809C55.7633 23.7059 55.9264 24.0033 56.1639 24.2352C56.4015 24.4671 56.7028 24.6229 57.0293 24.6828C57.3559 24.7426 57.6928 24.7038 57.9972 24.5712C58.3016 24.4387 58.5595 24.2184 58.7381 23.9386C58.9167 23.6587 59.0079 23.332 58.9999 23.0001C58.9895 22.5642 58.8089 22.1496 58.4969 21.845C58.1848 21.5404 57.766 21.37 57.3299 21.3701Z"
        fill="white"
      />
      <path
        d="M57.3299 21.3701C56.9979 21.3702 56.6735 21.4692 56.3981 21.6546C56.1227 21.8399 55.9087 22.1031 55.7836 22.4106C55.6584 22.7181 55.6278 23.0559 55.6955 23.3809C55.7633 23.7059 55.9264 24.0033 56.1639 24.2352C56.4015 24.4671 56.7028 24.6229 57.0293 24.6828C57.3559 24.7426 57.6928 24.7038 57.9972 24.5712C58.3016 24.4387 58.5595 24.2184 58.7381 23.9386C58.9167 23.6587 59.0079 23.332 58.9999 23.0001C58.9895 22.5642 58.8089 22.1496 58.4969 21.845C58.1848 21.5404 57.766 21.37 57.3299 21.3701Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.6099 106.87C56.6099 102.78 49.8899 103.16 47.4399 97.3501"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.94 16.5601C43.2811 16.4618 42.608 16.5664 42.01 16.8601"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M56.6299 16.56C57.2887 16.4636 57.9614 16.5682 58.5599 16.86"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.52 36.6399H42C41.4696 36.6399 40.9609 36.8506 40.5858 37.2257C40.2107 37.6008 40 38.1095 40 38.6399C40 39.1703 40.2107 39.679 40.5858 40.0541C40.9609 40.4292 41.4696 40.6399 42 40.6399H70.5C71.0304 40.6399 71.5391 40.4292 71.9142 40.0541C72.2893 39.679 72.5 39.1703 72.5 38.6399C72.5 38.1095 72.2893 37.6008 71.9142 37.2257C71.5391 36.8506 71.0304 36.6399 70.5 36.6399H70.52Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M70.52 36.6399H42C41.4696 36.6399 40.9609 36.8506 40.5858 37.2257C40.2107 37.6008 40 38.1095 40 38.6399C40 39.1703 40.2107 39.679 40.5858 40.0541C40.9609 40.4292 41.4696 40.6399 42 40.6399H70.5C71.0304 40.6399 71.5391 40.4292 71.9142 40.0541C72.2893 39.679 72.5 39.1703 72.5 38.6399C72.5 38.1095 72.2893 37.6008 71.9142 37.2257C71.5391 36.8506 71.0304 36.6399 70.5 36.6399H70.52Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M70.7899 13.0901H26.95"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.1801 105.4L70.6601 66.8201C70.5501 67.5701 71.5601 74.7101 72.5001 81.7201C72.5001 81.9801 73.5001 81.4201 73.5601 81.7201C75.3801 95.2801 78.6401 112.04 76.6201 125.4C76.0301 129.31 78.8401 130.33 78.7001 130.04C78.0365 128.541 77.8718 126.869 78.2301 125.27C78.5901 123.27 79.9101 122.27 79.2301 121.27L79.1801 105.4Z"
        fill="#2A2F3C"
      />
      <path
        d="M79.1801 105.4L70.6601 66.8201C70.5501 67.5701 71.5601 74.7101 72.5001 81.7201C72.5001 81.9801 73.5001 81.4201 73.5601 81.7201C75.3801 95.2801 78.6401 112.04 76.6201 125.4C76.0301 129.31 78.8401 130.33 78.7001 130.04C78.0365 128.541 77.8718 126.869 78.2301 125.27C78.5901 123.27 79.9101 122.27 79.2301 121.27L79.1801 105.4Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.6299 40.5901L41.1899 42.3601C46.7549 41.4609 52.3679 40.8899 57.9999 40.6501L41.6299 40.5901Z"
        fill="#2A2F3C"
      />
      <path
        d="M41.6299 40.5901L41.1899 42.3601C46.7549 41.4609 52.3679 40.8899 57.9999 40.6501L41.6299 40.5901Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.94 175.51L35.51 177.28C41.0648 176.382 46.668 175.814 52.29 175.58L35.94 175.51Z"
        fill="#2A2F3C"
      />
      <path
        d="M35.94 175.51L35.51 177.28C41.0648 176.382 46.668 175.814 52.29 175.58L35.94 175.51Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.9 175.51L56.47 177.28C62.0248 176.382 67.6279 175.814 73.25 175.58L56.9 175.51Z"
        fill="#2A2F3C"
      />
      <path
        d="M56.9 175.51L56.47 177.28C62.0248 176.382 67.6279 175.814 73.25 175.58L56.9 175.51Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.48 13L42 14.79C47.5583 13.8916 53.1647 13.3206 58.79 13.08L42.48 13Z"
        fill="#2A2F3C"
      />
      <path
        d="M42.48 13L42 14.79C47.5583 13.8916 53.1647 13.3206 58.79 13.08L42.48 13Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.5601 23.2C42.5105 23.2024 42.4636 23.2232 42.4285 23.2583C42.3933 23.2934 42.3725 23.3403 42.3701 23.39C42.3701 23.4403 42.3901 23.4887 42.4258 23.5243C42.4614 23.5599 42.5097 23.58 42.5601 23.58C42.5854 23.5814 42.6108 23.5775 42.6345 23.5685C42.6582 23.5594 42.6797 23.5455 42.6977 23.5275C42.7156 23.5096 42.7296 23.4881 42.7386 23.4643C42.7476 23.4406 42.7516 23.4153 42.7501 23.39C42.7501 23.3396 42.7301 23.2912 42.6945 23.2556C42.6588 23.22 42.6105 23.2 42.5601 23.2Z"
        fill="#2A2F3C"
      />
      <path
        d="M42.5601 23.2C42.5105 23.2024 42.4636 23.2232 42.4285 23.2583C42.3933 23.2934 42.3725 23.3403 42.3701 23.39C42.3701 23.4403 42.3901 23.4887 42.4258 23.5243C42.4614 23.5599 42.5097 23.58 42.5601 23.58C42.5854 23.5814 42.6108 23.5775 42.6345 23.5685C42.6582 23.5594 42.6797 23.5455 42.6977 23.5275C42.7156 23.5096 42.7296 23.4881 42.7386 23.4643C42.7476 23.4406 42.7516 23.4153 42.7501 23.39C42.7501 23.3396 42.7301 23.2912 42.6945 23.2556C42.6588 23.22 42.6105 23.2 42.5601 23.2V23.2Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.3999 23.6C56.4503 23.6 56.4987 23.58 56.5343 23.5443C56.5699 23.5087 56.59 23.4604 56.59 23.41C56.59 23.3596 56.5699 23.3113 56.5343 23.2756C56.4987 23.24 56.4503 23.22 56.3999 23.22C56.3496 23.22 56.3012 23.24 56.2656 23.2756C56.23 23.3113 56.21 23.3596 56.21 23.41C56.21 23.4604 56.23 23.5087 56.2656 23.5443C56.3012 23.58 56.3496 23.6 56.3999 23.6Z"
        fill="#2A2F3C"
      />
      <path
        d="M56.3999 23.6C56.4503 23.6 56.4987 23.58 56.5343 23.5443C56.5699 23.5087 56.59 23.4604 56.59 23.41C56.59 23.3596 56.5699 23.3113 56.5343 23.2756C56.4987 23.24 56.4503 23.22 56.3999 23.22C56.3496 23.22 56.3012 23.24 56.2656 23.2756C56.23 23.3113 56.21 23.3596 56.21 23.41C56.21 23.4604 56.23 23.5087 56.2656 23.5443C56.3012 23.58 56.3496 23.6 56.3999 23.6Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.5801 175.55V106.74"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.19 130.46C86.88 130.27 84.66 135.91 85.03 136.95C85.73 138.95 88.71 137.05 89.28 136.14C89.56 135.69 93.2801 138.73 93.5201 130.62C93.6101 128.06 91.8201 118.48 91.6301 115C90.76 99.0499 85.02 59.1899 71.7 42.4399C70.7 41.1899 69.86 42.0299 69.03 42.2199C67.73 42.5199 66.54 43.3399 65.26 47.8699C63.91 52.6499 66.09 64.1799 70.2 66.8199C73.31 81.2899 78.1 98.8899 78.7701 113.76C78.9101 116.94 79.2801 119.17 78.7701 121.01C78.34 122.56 77.1201 123.66 76.3201 126.56C75.7801 128.56 78.62 133.32 80.12 132.65C81.12 132.21 81.33 131.49 82.39 128.13C82.65 127.29 83.93 124.01 84.51 123.74C85.85 123 86.41 128.46 86.18 130.46"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M86.2301 129.31C86.2726 131.441 85.853 133.556 85.0001 135.51C84.4601 136.88 85.3301 137.51 86.3301 137.58C87.7301 137.73 88.8201 136.72 89.8701 133.93C90.6595 131.537 90.9687 129.012 90.7801 126.5C90.6601 125.06 92.4901 124.7 92.3201 122.5C91.0801 111.85 89.6201 69.7299 72.0801 42.1699"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.19 130.46C86.42 128.46 85.86 123 84.52 123.74C83.94 124.01 82.6599 127.29 82.3999 128.13C81.9799 129.47 81.8599 131.05 81.5399 131.77C80.4699 134.22 77.25 131.05 76.71 128.86C75.71 124.65 78.59 121.55 78.78 121.01C79.41 119.19 78.87 116.01 78.78 113.76C78.11 98.8901 73.32 81.2901 70.21 66.8201"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.05 124.83C86.92 127.83 86.05 136.29 83.29 137.93C81.95 138.74 80.16 138.11 79.95 137.23C79.46 135.1 80.61 134.54 81.17 131.46C81.6407 128.776 81.6744 126.034 81.27 123.34C80.88 121.26 83.54 119.82 85.49 123.54C85.7289 123.945 85.917 124.379 86.05 124.83Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M86.05 124.83C86.92 127.83 86.05 136.29 83.29 137.93C81.95 138.74 80.16 138.11 79.95 137.23C79.46 135.1 80.61 134.54 81.17 131.46C81.6407 128.776 81.6744 126.034 81.27 123.34C80.88 121.26 83.54 119.82 85.49 123.54C85.7289 123.945 85.917 124.379 86.05 124.83V124.83Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M91.91 119C91.81 119.54 93.77 126.37 93.38 131.17C93.17 133.84 91.54 137.3 89.28 136.1"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.1901 117.72C83.1229 117.777 82.1199 118.247 81.3937 119.031C80.6674 119.815 80.2752 120.852 80.3001 121.92C80.2636 122.442 80.3316 122.966 80.5002 123.461C80.6688 123.956 80.9346 124.413 81.2821 124.804C81.6295 125.195 82.0515 125.513 82.5234 125.739C82.9953 125.965 83.5075 126.095 84.0301 126.12C85.0973 126.063 86.1004 125.593 86.8266 124.809C87.5528 124.025 87.945 122.988 87.9201 121.92C87.9567 121.398 87.8887 120.874 87.7201 120.379C87.5514 119.884 87.2856 119.427 86.9382 119.036C86.5908 118.645 86.1688 118.327 85.6969 118.101C85.225 117.875 84.7127 117.745 84.1901 117.72Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.86 118.44C90.04 118.44 87.03 125.84 89.98 126.12C92.93 126.4 91.13 131.12 91.98 131.12C92.83 131.12 92.98 128.48 92.98 127.26C92.98 126.04 91.67 118.41 90.86 118.41"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M81.4 125.41C81.4 124.54 80.65 124.26 80.51 125.59C80.4126 126.738 80.7012 127.885 81.33 128.85"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M49.3401 26.3899C48.5301 26.5899 47.7601 27.6599 48.9601 28.0999C50.8401 28.8099 47.3901 29.5499 48.9601 30.7399C49.2401 30.9499 49.6401 30.7999 49.9601 30.7399"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M4.71997 37.3601C5.59402 37.2821 6.47491 37.3428 7.32997 37.5401"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M3.28003 42.1101C3.52003 41.9801 7.61003 41.2001 8.10003 41.1101"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M43.43 18.4699C44.3157 18.462 45.1839 18.7174 45.9242 19.2037C46.6645 19.69 47.2437 20.3854 47.5882 21.2014C47.9326 22.0175 48.0269 22.9175 47.8589 23.7872C47.691 24.6569 47.2685 25.4571 46.6449 26.0863C46.0214 26.7154 45.225 27.1451 44.3569 27.3209C43.4887 27.4966 42.5879 27.4105 41.7688 27.0733C40.9497 26.7362 40.2492 26.1633 39.7562 25.4274C39.2632 24.6915 39 23.8257 39 22.9399C39 21.7613 39.4654 20.6303 40.2951 19.7932C41.1247 18.956 42.2514 18.4805 43.43 18.4699V18.4699Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M57.1302 18.47C58.0142 18.47 58.8785 18.7321 59.6136 19.2233C60.3486 19.7145 60.9216 20.4126 61.2599 21.2294C61.5982 22.0462 61.6867 22.9449 61.5143 23.812C61.3418 24.6791 60.9161 25.4756 60.2909 26.1007C59.6658 26.7259 58.8693 27.1516 58.0022 27.3241C57.1351 27.4966 56.2363 27.408 55.4196 27.0697C54.6028 26.7314 53.9047 26.1585 53.4135 25.4234C52.9223 24.6883 52.6602 23.8241 52.6602 22.94C52.6602 21.7545 53.1311 20.6175 53.9694 19.7792C54.8077 18.9409 55.9446 18.47 57.1302 18.47V18.47Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M61.8301 22.3401H70.7801"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M47.4299 21.0001C48.4299 19.1801 51.9499 19.0001 53.3699 20.8301"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M16.9299 24.8499L17.5199 20.9199"
        stroke="#22262F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M19.8901 26.99L24.4901 23.75"
        stroke="#22262F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M25.2101 30.4199L21.8901 30.9599"
        stroke="#22262F"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_19425_3735">
        <rect width="94" height="193" fill="white" />
      </clipPath>
    </defs>
  </ChubbsWrapper>
));
