import React, { FC } from 'react';
import { ChubbsProps } from '@/components/ui/Chubbs/chubbs.typedefs';
import { Chubbs } from '@/components/ui/Chubbs/Chubbs';
import {
  ChubbsReadingLight,
} from '@/components/ui/Chubbs/ChubbsReading/ChubbsReading.light';
import {
  ChubbsReadingDark,
} from '@/components/ui/Chubbs/ChubbsReading/ChubbsReading.dark';

export const ChubbsReading: FC<ChubbsProps> = React.memo((props) => (
  <Chubbs
    LightChubbs={ChubbsReadingLight}
    DarkChubbs={ChubbsReadingDark}
    {...props}
  />
));
