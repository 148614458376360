import React, { FC } from 'react';
import { ChubbsProps } from '@/components/ui/Chubbs/chubbs.typedefs';
import { Chubbs } from '@/components/ui/Chubbs/Chubbs';
import {
  ChubbsSendMessageLight,
} from '@/components/ui/Chubbs/ChubbsSendMessage/ChubbsSendMessage.light';
import {
  ChubbsSendMessageDark,
} from '@/components/ui/Chubbs/ChubbsSendMessage/ChubbsSendMessage.dark';

export const ChubbsSendMessage: FC<ChubbsProps> = React.memo((props) => (
  <Chubbs
    LightChubbs={ChubbsSendMessageLight}
    DarkChubbs={ChubbsSendMessageDark}
    {...props}
  />
));
