import React, { ReactElement } from 'react';
import { cn } from '@/lib/classNames';
import { TabData, TabRenderProps } from '@/components/platform/Tabs/Tabs.typedefs';
import styles from './Tabs.module.scss';

interface Props<T extends TabData> {
  activeTab: any;
  setActiveTab?: (id: any) => void;
  tabsData: readonly T[];
  renderTab?: (tabData: TabRenderProps & T) => ReactElement;
  containerStyleDeprecated?: string;
  isCompact?: boolean;
  isCentered?: boolean;
  isWide?: boolean;
  customStyle?: React.CSSProperties;
  tabsRef?: React.RefObject<HTMLDivElement>;
}

type ComponentType = <T extends TabData>(props: Props<T>) => ReactElement;

export const Tabs: ComponentType = (props) => {
  const {
    tabsData,
    activeTab,
    renderTab = ({ title }: TabData) => title,
    containerStyleDeprecated = '',
    isCompact,
    isCentered,
    isWide,
    customStyle,
    tabsRef,
  } = props;

  const className = cn(
    styles.container,
    containerStyleDeprecated,
    {
      [styles.compact]: isCompact,
      [styles.centered]: isCentered,
    },
  );

  return (
    <div
      className={className}
      ref={tabsRef}
      style={customStyle}
    >
      {tabsData.map((tabData, index) => {
        const {
          id,
        } = tabData;

        const isActive = activeTab === id;

        return (
          <React.Fragment key={id}>
            {
              renderTab({
                ...tabData,
                isActive,
                index,
                className: cn(styles.wrapper, {
                  [styles.active]: isActive,
                  [styles.wide]: isWide,
                }),
              })
            }
          </React.Fragment>
        );
      })}
    </div>
  );
};
