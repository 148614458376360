export const SIDEBAR_MIN_WIDTH = 48;
export const FILE_MANAGER_MIN_WIDTH = 180;

export const NEW_PRACTICE_SIDEBAR_MIN_WIDTH = '0%';
export const NEW_PRACTICE_SIDEBAR_MAX_WIDTH = '40%';

export const SIDEBAR_DEFAULT_WIDTH_IN_PERCENT = '15%';
export const SIDEBAR_DEFAULT_HEIGHT = '100%';

export const SIDEBAR_DEFAULT_WIDTH = 350;

export const LEFT_SIDEBAR_DEFAULT_WIDTH_IN_PERCENT = '25%';
export const LEFT_SIDEBAR_MIN_WIDTH = 350;
export const LEFT_SIDEBAR_MAX_WIDTH_IN_PERCENT = '40%';
export const LEFT_SIDEBAR_KEY = 'linear_flow_left_sidebar';

export const RIGHT_SIDEBAR_DEFAULT_WIDTH_IN_PERCENT = '25%';
export const RIGHT_SIDEBAR_MIN_WIDTH = 350;
export const RIGHT_SIDEBAR_MAX_WIDTH_IN_PERCENT = '40%';
export const RIGHT_SIDEBAR_KEY = 'linear_flow_right_sidebar';

export const MINIMIZED_SIDEBAR_WIDTH = 50;

export const FULLSCREEN_TASK_BLOCK_WIDTH = '0';
export const FULLSCREEN_RESULTS_BLOCK_WIDTH = '';
export const MOBILE_TASK_BLOCK_WIDTH = '100%';
export const MOBILE_RESULTS_BLOCK_WIDTH = '0';

export const LIGHT_FUNNEL_RATING_CIRCLE_SIZE = {
  Desktop: 120,
  Mobile: 60,
};

export enum ResizeHandlers {
  Resize = 'resize',
  OpenRightSidebar = 'openRightSidebar',
  CloseRightSidebar = 'closeRightSidebar',
  OpenLeftSidebar = 'openLeftSidebar',
  CloseLeftSidebar = 'closeLeftSidebar',
}

export type ResizedHandlerEvent = {
  type: ResizeHandlers;
};

export const LEARNER_HEADER_HEIGHT = 72;
export const GAP_BETWEEN_MODULES = 24;

export const MOCKED_UNDEFINED = '"unlikely-to-appear-mocked-undefined"';
