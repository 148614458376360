import {
  ChubbsPlaceholderType,
} from '@/components/ui/ChubsPlaceholder/chubbsPlaceholderType';
import {
  ChubbsWorking,
} from '@/components/ui/Chubbs/ChubbsWorking/ChubbsWorking';
import {
  ChubbsUndefined,
} from '@/components/ui/Chubbs/ChubbsUndefined/ChubbsUndefined';
import {
  ChubbsSendMessage,
} from '@/components/ui/Chubbs/ChubbsSendMessage/ChubbsSendMessage';
import { ChubbsLike } from '@/components/ui/Chubbs/ChubbsLike/ChubbsLike';
import {
  ChubbsReading,
} from '@/components/ui/Chubbs/ChubbsReading/ChubbsReading';
import { ChubbsPhone } from '@/components/ui/Chubbs/ChubbsPhone/ChubbsPhone';

export const CHUBS_PLACEHOLDER_ICON_MAP = {
  [ChubbsPlaceholderType.Typing]: ChubbsWorking,
  [ChubbsPlaceholderType.Undefined]: ChubbsUndefined,
  [ChubbsPlaceholderType.Phone]: ChubbsPhone,
  [ChubbsPlaceholderType.Send]: ChubbsSendMessage,
  [ChubbsPlaceholderType.Fine]: ChubbsLike,
  [ChubbsPlaceholderType.Reading]: ChubbsReading,
};

export const CHUBS_PLACEHOLDER_PROPS_MAP = {
  [ChubbsPlaceholderType.Typing]: { width: 100, height: 140 },
  [ChubbsPlaceholderType.Undefined]: { width: 124, height: 136 },
  [ChubbsPlaceholderType.Phone]: { width: 100, height: 140 },
  [ChubbsPlaceholderType.Send]: { width: 130, height: 120 },
  [ChubbsPlaceholderType.Fine]: { width: 106, height: 134 },
  [ChubbsPlaceholderType.Reading]: { width: 100, height: 136 },
};
